import { useStateIfMounted } from 'use-state-if-mounted';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useMediaQuery,
} from '@mui/material';

export const FilterItem = styled(({ disableRipple, ...props }) => (
  <ListItemButton disableRipple={disableRipple} {...props} />
))(({ theme, collapsed }) => ({
  height: 56,
  borderBottom: collapsed ? 'none' : `1px solid ${theme.palette.border.divider}`,
  width: 340,
  '@media(max-width: 480px)': {
    width: '100%',
  },

  '&:hover': {
    backgroundColor: theme.palette.background.hover,
  },
  '&:active': {
    backgroundColor: theme.palette.background.select,
  },
}));

export const FilterIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'auto',
  marginRight: theme.spacing(2),
}));

export const FilterText = styled(ListItemText)(() => ({
  '& span': {
    fontSize: 16,
    fontWeight: 600,
  },
}));

export const CollapsableFilterItem = ({ title, children }) => {
  const [open, setOpen] = useStateIfMounted(false);
  return (
    <>
      <FilterItem
        open={open}
        timeout="auto"
        onClick={() => setOpen(!open)}
        collapsed={open ? 'collapsed' : ''}
      >
        <FilterText>{title}</FilterText>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </FilterItem>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
};

export const MobileFilterBar = styled(Box)(({ theme, button, height }) => ({
  borderTop: !button && `1px solid ${theme.palette.border.divider}`,
  borderBottom: !button && `1px solid ${theme.palette.border.divider}`,
  padding: theme.spacing(1, 2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: height || 'auto',
}));

const FiltersButtonComponent = styled(Button)(({ theme }) => ({
  fontSize: 15,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

export const FiltersButton = ({ onClick = () => {} }) => {
  return (
    <FiltersButtonComponent startIcon={<FilterAltIcon />} onClick={onClick}>
      Filters
    </FiltersButtonComponent>
  );
};

export const FullMobileFiltersBar = ({ toggle, button, height }) => {
  const below480 = useMediaQuery('(max-width: 480px)');
  return below480 ? (
    <MobileFilterBar button={button} height={height}>
      <FiltersButton onClick={toggle} />
    </MobileFilterBar>
  ) : null;
};
